export enum ProviderStatus {
  NA = "NA",
  LOADING = "LOADING",
  CONFIGURED = "CONFIGURED",
  ERROR = "ERROR",
  NO_AVAILABILITY = "NO_AVAILABILITY",
  SELECTION_NEEDED = "SELECTION_NEEDED",
  SUSPENDED = "SUSPENDED",
}

export interface ProviderState {
  status: ProviderStatus;
  profileSummary?: ProfileSummary;
  profile?: Profile;
}

export type ProfileSummary = {
  provider_id: string;
};
type Study = {
  sort_order: number;
  study_type: string;
  description_short: string;
};
type Specialization = {
  slug: string;
  name: string;
};

export type Profile = {
  provider_id: string;
  name: string;
  description_short: string;
  description_long: string;
  studies: Study[];
  specializations: Specialization[];
  slots?: any[];
} & Record<string, any>;
