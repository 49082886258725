import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, take, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import { isClient } from "@/core/utils/nextjs";
import {
  REFERRAL_AFFILIATE_COOKIE_KEY,
  REFERRAL_AFFILIATE_URL_KEY,
  REFERRAL_PROVIDER_COOKIE_KEY,
  REFERRAL_PROVIDER_URL_KEY,
  TRACKING_URL_FIRST_STORAGE_KEY,
  TRACKING_URL_LAST_STORAGE_KEY,
  TRACKING_UTM_CAMPAIGN_COOKIE_KEY,
  TRACKING_UTM_MEDIUM_COOKIE_KEY,
  TRACKING_UTM_SOURCE_COOKIE_KEY,
} from "@/core/constants";
import { sendClientGTMEvent } from "@/core/utils/tracking";
import { emailsApi } from "@/core/api/services/emails";
import { getStorageValue } from "@/core/utils/storage";
import { trackingActions } from "./actions";
import { ReferralInfo, ReferralInfoType, TrackingConfig, TrackingUTMParams } from "./types";
import { trackingSelectors } from "./selectors";
import { siteActions } from "../site/actions";
import { SiteConfig } from "../site/types";
import { authActions } from "../auth/actions";
import { User } from "../auth/types";
import { appActions } from "../app/actions";
import { AppStatus } from "../app/types";
import { authSelectors } from "../auth/selectors";
import { contextActions } from "../context/actions";
import { contextSelectors } from "../context/selectors";
import { routerSelectors } from "../router/selectors";
import { routerActions } from "../router/actions";
import { callRTKQuery } from "../api/sagas";

function* onSiteSetCodeWithConfig(action: PayloadAction<{ config: SiteConfig }>): Generator {
  const tracking = action.payload.config?.tracking;
  if (tracking) {
    yield put(trackingActions.setConfig(tracking as TrackingConfig));
  }
}

function* onAppSetStatus(action: PayloadAction<AppStatus>): Generator {
  if (action.payload === AppStatus.READY_PARTIAL) {
    yield setInitialUTMParams(true);
    yield setInitialProviderRefferal();
    yield setInitialAffiliateRefferal();
  } else if (isClient() && action.payload === AppStatus.READY_FULL) {
    // Set user if available
    const user = (yield select(authSelectors.user)) as User | undefined;
    if (user?.id) {
      Sentry.setUser({ customer_id: user.id, type: "customer", name: user.name });
      sendClientGTMEvent({ customerId: user.id });
    }
    // load dynamic scripts
    const scripts = (yield select(trackingSelectors.dynamicScripts)) as string[] | undefined;
    if (scripts?.length) {
      yield injectDynamicScripts(scripts);
    }

    // Set UTM params if available
    const utmParams = (yield select(trackingSelectors.utm_params)) as TrackingUTMParams | undefined;
    if (utmParams) {
      sendClientGTMEvent({ utmParams: utmParams });
    }
    yield setUrlFirstLastParams();
  }
}

function* setInitialUTMParams(initial = false): Generator {
  try {
    const urlParams = (yield select(routerSelectors.urlParams)) as URLSearchParams;
    if (urlParams.get("utm_source") && urlParams.get("utm_medium") && urlParams.get("utm_campaign")) {
      const utmParams: TrackingUTMParams = {
        utm_source: urlParams.get("utm_source") || undefined,
        utm_medium: urlParams.get("utm_medium") || undefined,
        utm_campaign: urlParams.get("utm_campaign") || undefined,
      };
      yield put(trackingActions.setUTMParams(utmParams));
      yield put(contextActions.setCookie({ key: TRACKING_UTM_SOURCE_COOKIE_KEY, value: utmParams.utm_source, ttl: 0 }));
      yield put(contextActions.setCookie({ key: TRACKING_UTM_MEDIUM_COOKIE_KEY, value: utmParams.utm_medium, ttl: 0 }));
      yield put(
        contextActions.setCookie({ key: TRACKING_UTM_CAMPAIGN_COOKIE_KEY, value: utmParams.utm_campaign, ttl: 0 }),
      );
      return;
    }
  } catch (error) {
    console.error("Failed to get URL params", error);
  }

  if (initial) {
    const utmSource = yield select(contextSelectors.requestCookieValue, TRACKING_UTM_SOURCE_COOKIE_KEY);
    if (!utmSource) return;
    const utmMedium = yield select(contextSelectors.requestCookieValue, TRACKING_UTM_MEDIUM_COOKIE_KEY);
    if (!utmMedium) return;
    const utmCampaign = yield select(contextSelectors.requestCookieValue, TRACKING_UTM_CAMPAIGN_COOKIE_KEY);
    if (!utmCampaign) return;

    if (utmSource && utmMedium && utmCampaign) {
      const existingParams = (yield select(trackingSelectors.utm_params)) as TrackingUTMParams | {};
      console.log("Restoring previous UTM params from cookie");
      const utmParams: TrackingUTMParams = {
        ...existingParams,
        utm_source: utmSource as string,
        utm_medium: utmMedium as string,
        utm_campaign: utmCampaign as string,
      };
      yield put(trackingActions.setUTMParams(utmParams));
    }
  }
}

function* setUrlFirstLastParams(): Generator {
  // Set other UTM params if available
  if (!isClient()) return;
  const urlFirst = getStorageValue(TRACKING_URL_FIRST_STORAGE_KEY);
  const urlLast = getStorageValue(TRACKING_URL_LAST_STORAGE_KEY);
  if (!urlFirst || !urlLast) return;
  const existingParams = (yield select(trackingSelectors.utm_params)) as TrackingUTMParams | {};
  const utmParams: TrackingUTMParams = {
    ...existingParams,
    url_first: urlFirst,
    url_last: urlLast,
  };
  yield put(trackingActions.setUTMParams(utmParams));
}

function* setInitialProviderRefferal(): Generator {
  const providerReferral = yield select(contextSelectors.requestCookieValue, REFERRAL_PROVIDER_COOKIE_KEY);
  if (providerReferral) {
    const referral: ReferralInfo = {
      type: ReferralInfoType.PROVIDER,
      code: providerReferral as string,
    };
    yield put(trackingActions.setReferral(referral));
  }
  try {
    const urlParams = (yield select(routerSelectors.urlParams)) as URLSearchParams;
    if (urlParams.get(REFERRAL_PROVIDER_URL_KEY)) {
      const referralCode = urlParams.get(REFERRAL_PROVIDER_URL_KEY);
      if (referralCode) {
        const referral: ReferralInfo = {
          type: ReferralInfoType.PROVIDER,
          code: referralCode as string,
        };
        yield put(trackingActions.setReferral(referral));
        yield put(contextActions.setCookie({ key: REFERRAL_PROVIDER_COOKIE_KEY, value: referral.code }));
      }
    }
  } catch (error) {
    console.error("Failed to get URL params", error);
  }
}

function* setInitialAffiliateRefferal(): Generator {
  const affiliateCode = yield select(contextSelectors.requestCookieValue, REFERRAL_AFFILIATE_COOKIE_KEY);
  if (affiliateCode) {
    const referral: ReferralInfo = {
      type: ReferralInfoType.AFFILIATE,
      code: affiliateCode as string,
    };
    yield put(trackingActions.setReferral(referral));
  }
  try {
    const urlParams = (yield select(routerSelectors.urlParams)) as URLSearchParams;
    if (urlParams.get(REFERRAL_AFFILIATE_URL_KEY)) {
      const affiliateCode = urlParams.get(REFERRAL_AFFILIATE_URL_KEY);
      if (affiliateCode) {
        const referral: ReferralInfo = {
          type: ReferralInfoType.AFFILIATE,
          code: affiliateCode as string,
        };
        yield put(trackingActions.setReferral(referral));
        yield put(contextActions.setCookie({ key: REFERRAL_AFFILIATE_COOKIE_KEY, value: referral.code }));
      }
    }
  } catch (error) {
    console.error("Failed to get URL params", error);
  }
}

function* injectDynamicScripts(scripts: string[]): Generator {
  if (!isClient()) return;

  try {
    // Jnject dynamic scripts at the end of the body and make sure that they are found only once
    const body = document.querySelector("body");
    if (!body) return;
    const existingScripts = body.querySelectorAll("script[data-dynamic]");
    existingScripts.forEach((script) => script.remove());
    for (const src of scripts) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.dataset.dynamic = "true";
      body.appendChild(script);
    }
  } catch (error) {
    console.error("Failed to inject dynamic tags", error);
  }
}

function* onAuthSetUser(action: PayloadAction<User>): Generator {
  if (!isClient()) return;
  const user = action.payload;
  if (user?.id) {
    Sentry.setUser({ customer_id: user.id, type: "customer", name: user.name });
    sendClientGTMEvent({ customerId: user.id });
  }
}

function* onAuthLogout(action: PayloadAction): Generator {
  if (!isClient()) return;
  Sentry.setUser(null);
  sendClientGTMEvent({ customerId: undefined });
}

function* onCheckForEmailClickTracking(_action: PayloadAction<string | undefined>): Generator {
  if (isClient()) {
    console.log("Email tracking is not done from client side");
    return;
  }
  const urlParams = (yield select(routerSelectors.urlParams)) as URLSearchParams;
  if (urlParams.get("tracker_id")) {
    try {
      yield callRTKQuery(
        emailsApi.endpoints.emailClick.initiate({
          tracker_id: urlParams.get("tracker_id"),
        }),
      );
    } catch (error) {
      console.error("Failed to track email click", error);
    }
  }
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.setCodeWithConfig, onSiteSetCodeWithConfig);
  yield takeLatest(appActions.setStatus, onAppSetStatus);
  yield takeLatest(authActions.setUser, onAuthSetUser);
  yield takeLatest(authActions.logout, onAuthLogout);
  yield takeLatest(routerActions.setUrl, onCheckForEmailClickTracking);
}
