import { createApi } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { baseQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Slots"],
  endpoints: (builder) => ({
    getSlots: builder.query<any, any>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers-bookings/customers/slots`, queryParams),
      providesTags: ["Slots"],
    }),
    rejectInvitation: builder.mutation<any, any>({
      query: ({ slot_id }) => ({
        url: `/api/providers-customers-bookings/customers/slots/${slot_id}/reject`,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
    createSlotPaymentInfo: builder.mutation<any, any>({
      query: ({ slot_id, ...data }) => ({
        url: `/api/providers-customers-bookings/customers/slots/${slot_id}/create-payment-info`,
        method: "POST",
        body: data,
      }),
    }),
    checkVoucherForPaymentInfo: builder.mutation<any, any>({
      query: ({ slot_id, ...data }) => ({
        url: `/api/providers-customers-bookings/customers/slots/${slot_id}/check-voucher`,
        method: "POST",
        body: data,
      }),
    }),

    createBookingRating: builder.mutation<any, { data: any }>({
      query: (data: any) => ({
        url: `/api/providers-ratings/customers/bookings`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Slots"],
    }),
  }),
});

export const publicBookingsApi = createApi({
  reducerPath: "publicBookingsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Slots"],
  endpoints: (builder) => ({
    getSlotPaidInfo: builder.query<any, string>({
      query: (slot_id) => `/api/providers-customers-bookings/public/slot/${slot_id}/paid`,
    }),
  }),
});

export const {
  useGetSlotsQuery,
  useRejectInvitationMutation,
  useCreateSlotPaymentInfoMutation,
  useCheckVoucherForPaymentInfoMutation,
  useCreateBookingRatingMutation,
} = bookingsApi;
export const { useLazyGetSlotPaidInfoQuery } = publicBookingsApi;
