// utils to interact with the local storage

import { isClient } from "./nextjs";

let localStorageIsAvailable: boolean | null = null;

export const isAvailable = (): boolean => {
  if (!isClient() || !window?.localStorage) {
    return false;
  }
  if (localStorageIsAvailable === null) {
    try {
      const key = "__storage_test__";
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      localStorageIsAvailable = true;
    } catch (e) {
      localStorageIsAvailable = false;
    }
  }
  return localStorageIsAvailable;
};

export const hasStorageValue = (key: string): boolean => {
  if (!isAvailable()) {
    return false;
  }
  return Boolean(getStorageValue(key));
};

export const getStorageValue = (key: string): string | null => {
  if (!isAvailable()) {
    return null;
  }
  return localStorage.getItem(key);
};

export const setStorageValue = (key: string, value: string): void => {
  if (!isAvailable()) {
    return;
  }
  localStorage.setItem(key, value);
};

export const setObj = (key: string, obj: any): void => {
  setStorageValue(key, JSON.stringify(obj));
};

export const getObj = (key: string): any => {
  const value = getStorageValue(key);
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
